import InfoItem from "../InfoCardSmall/InfoCardSmall";
import cls from "./BenefitsSection.module.scss";
import { classNames } from "@/shared/lib/classNames";
import IMG1 from '../../assets/img/benefits_1.jpeg'
import IMG2 from '../../assets/img/benefits_2.webp'
import IMG3 from '../../assets/img/benefits_3.jpg'
import IMG4 from '../../assets/img/benefits_4.jpg'

export const BenefitsSection: React.FC = () => {

  const items = [
    {pic:IMG1, text: 'Безопасно для любого типа кожи'},
    {pic:IMG2, text: 'Максимально безболезненно'},
    {pic:IMG3, text: 'Результат заметен сразу'},
    {pic:IMG4, text: 'Экономия вашего времени'}]

  return (
    <section className={classNames(cls.BenefitsSection, {}, ['container'])}>
      {items.map((item, index) => <InfoItem url={item.pic} text={item.text}/>)}
    </section>
  );
};