import cls from "./MapSection.module.scss";
import { classNames } from "@/shared/lib/classNames";
import { FullscreenControl, Map, Placemark } from "@pbe/react-yandex-maps";




export const MapSection: React.FC = () => {

  return (
    <section  className={classNames(cls.MapSection, {}, ['container'])}>
      <iframe 
      title="map"
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A36bf45fd11884532726fa08545b6e7b1b0c5919c2062024a4dc1a18678cd2fc9&amp;source=constructor" 
      width="100%" 
      height="400" 
      frameBorder="0"
      />
    </section>
  );
};