import cls from "./HeroSection.module.scss";
import { classNames } from "@/shared/lib/classNames";




export const HeroSection: React.FC = () => {
  
  return (
    <section className={classNames(cls.HeroSection, {}, ['container'])}>
        <div className={cls.image}></div>
        <div className={cls.info}>
            <h1>Получите гладкую кожу уже сегодня!</h1>
            <p>Единственное о чем вы будете жалеть, что не сделали этого раньше!</p>
            <a href='https://dikidi.ru/#widget=159796'>Записаться</a>
            </div>
    </section>
  );
};