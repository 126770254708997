import { classNames } from "@/shared/lib/classNames";
import cls from "./Footer.module.scss";
import { phone, phoneLink } from "@/shared/model/constants/phones";
import { address, addressLink } from "@/shared/model/constants/address";
import WhatsappSVG from '../../../shared/assets/svg/whatsapp.svg'
import TelegramSVG from '../../../shared/assets/svg/telegram.svg'

export const Footer: React.FC = () => {
  return (
    <footer className={classNames(cls.Footer, {}, ['container'])}>
        <div className={cls.logo}>
          <span>LASERDI</span>
          <span>САЛОН ЛАЗЕРНОЙ ЭПИЛЯЦИИ</span>
          </div>
        <div className={cls.contacts}>
          <a href={phoneLink} className={cls.phone}>{phone}</a> 
          <a href={`https://wa.me/${phone.replace(/\D/gi,'')}`}><img src={WhatsappSVG}/></a>
          <a href={`https://t.me/+${phone.replace(/\D/gi,'')}`}><img src={TelegramSVG}/></a>
          </div>
        <a href={addressLink} className={cls.address}>{address}</a>
    </footer>
  );
};
