import { useState } from "react";
import cls from "./QuestionsSection.module.scss";
import { classNames } from "@/shared/lib/classNames";
import { Accordeon } from "../Accordeon/Accordeon";




export const QuestionsSection: React.FC = () => {



  return (
    <section className={classNames(cls.QuestionsSection, {}, ['container'])}>
      <h1>Часто задаваемые вопросы</h1>
      <div className={cls.content}>
        <div>
          <Accordeon title="Как подготовиться к лазерной эпиляции" >
            Накануне вечером до процедуры необходимо побрить участки тела ,которые будут подвергаться лазерной
            эпиляции
            <br/>
            <br/>
            За 3-4 дня до процедуры необходимо прервать использование спиртосодержащей косметики.
            Кожа должна быть чистой, перед процедурой не используйте косметические средства (крема, масла и т.д)
            За 3 недели до проведения лазерной эпиляции следует исключить все виды депиляции (шугаринг, воск,
            пинцет).
            <br/>
            <br/>
            За 10–14 дней до проведения процедуры нельзя посещать солярий или загорать под прямыми солнечными
            лучами.
          </Accordeon>
          <Accordeon title="Уход за кожей после лазерной эпиляции" >
            Необходимо исключить воздействие высоких температур (бани, сауны, хамам, горячие ванны), массаж, посещение бассейна в течение 3 дней
            <br/>
            <br/>
            Не травмировать кожу (использование скрабов, мочалок и других раздражающих факторов) в течение 3-4 дней после процедуры
            <br/>
            <br/>
            В течение 14 дней бережем кожу от солнца. Наносим солнцезащитный крем SPF 50
            <br/>
            <br/>
            Нельзя обрабатывать кожу спиртосодержащими средствами в течение 12 часов
          </Accordeon>
          <Accordeon title="Больно ли делать лазерную эпиляцию в вашей студии?" >
            Мощная система охлаждения позволяет делать процедуру абсолютно безболезненно и БЫСТРО,  даже в самых чувствительных зонах!
          </Accordeon>
        </div>
        <div>
    
          <Accordeon title="Через сколько я увижу результат?" >
            Через 10-14 дней после первой процедуры ЛЭ вы увидите изменения: часть волос выпадает, остальные замедляют свой рост.
          </Accordeon>
          <Accordeon title="Вредна ли лазерная эпиляция?" >
            Лазерный луч воздействует только на меланин (пигмент который содержится в волосе)
            И не воздействует на внутренние органы, так как глубина проникновения лазерного луча при эпиляции составляет 2-3 мм
          </Accordeon>
          <Accordeon title="Необходимо ли выполнять полный курс лазерной эпиляции?" >
            Лазер эффективно воздействует только на те волоски которые находятся в активной стадии роста! Поэтому для полного прекращения роста волос, необходимо сделать курс
          </Accordeon>
        </div>


      </div>
    </section>
  );
};