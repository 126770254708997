import { classNames } from "@/shared/lib/classNames";
import cls from "./Accordeon.module.scss";
import { ReactNode, useState } from "react";

interface AccordeonProps {
    title?:string
    children?:ReactNode
}

export const Accordeon: React.FC<AccordeonProps> = (props) => {
    const {title, children} = props

    const [active, setActive] = useState(false)

    const keyPressHandler = (e:any) => {
      e.preventDefault()
      if (e.key === 'Enter' || e.key === 'NumpadEnter') {
        setActive(!active)
      }
      
    }

    return (
        <div className={classNames(cls.Accordeon, {}, [active ? cls.active : ""])}>
        <div className={cls.header} role="presentation" onKeyUp={keyPressHandler} tabIndex={0} onClick={() => setActive(!active)}>
          <p>{title}</p>
          <div className={cls.icon} />
        </div>
        <div className={cls.body}>
         {children}
        </div>
      </div>
    );
  };