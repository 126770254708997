export const formatMoney = (
    number: string | number | null | undefined,
    showSymbol = true,
    delimiter = '.'
  ): string => {
    if (number === undefined || number === null || !String(number).length)
      return ''
  
    const parts = String(number).split('.')
  
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  
    let money = parts.join(delimiter)
  
    if (showSymbol) {
      money += '₽'
    }
  
    return money
  }
  