import React from 'react';
import './styles/index.scss'
import { Header } from '../widgets/header';
import { HeroSection } from '@/shared/ui/HeroSection/HeroSection';
import { BenefitsSection } from '@/shared/ui/BenefitsSection/BenefitsSection';
import { ServicesSection } from '@/shared/ui/ServicesSection/ServicesSection';
import { QuestionsSection } from '@/shared/ui/QuestionsSection/QuestionsSection';
import { MapSection } from '@/shared/ui/MapSection/MapSection';
import { YMaps } from '@pbe/react-yandex-maps';
import { Footer } from '@/widgets/footer/ui/Footer';

function App() {
  return (
    <div className="app">
       <YMaps>

     <Header />
        <main>
          <HeroSection />
          <BenefitsSection />
          <ServicesSection />
          <QuestionsSection />
          <MapSection />
        </main>
        <Footer />
       </YMaps>
    </div>
  );
}

export default App;
