import { classNames } from "@/shared/lib/classNames";
import cls from "./Header.module.scss";
import { phone, phoneLink } from "@/shared/model/constants/phones";
import PhoneSVG from '../../../shared/assets/svg/call.svg'
import WhatsappSVG from '../../../shared/assets/svg/whatsapp.svg'
import TelegramSVG from '../../../shared/assets/svg/telegram.svg'

export const Header: React.FC = () => {
  return (
    <header className={classNames(cls.Header, {}, ['container'])}>
        <a href="/" className={cls.logo}><span>LASERDI</span><span>САЛОН ЛАЗЕРНОЙ ЭПИЛЯЦИИ</span></a>
        <div className={cls.contacts}>
          <a className={cls.phone} href={phoneLink}>{phone}</a>
          <a className={cls.phoneIcon} href={phoneLink}><img src={PhoneSVG}/></a>
          <a href={`https://wa.me/${phone.replace(/\D/gi,'')}`}><img src={WhatsappSVG}/></a>
          <a href={`https://t.me/+${phone.replace(/\D/gi,'')}`}><img src={TelegramSVG}/></a>
      </div>
    </header>
  );
};
