import { manPrice, womanPrice } from "@/shared/model/constants/price";
import cls from "./ServicesSection.module.scss";
import { classNames } from "@/shared/lib/classNames";
import { formatMoney } from "@/shared/lib/format";
import { ListItem } from "../ListItem/ListItem";
import { phone, phoneLink } from "@/shared/model/constants/phones";
import WhatsappSVG from '../../../shared/assets/svg/whatsapp.svg'
import TelegramSVG from '../../../shared/assets/svg/telegram.svg'
import { useEffect, useState } from "react";

type DikidiService = {
  id: number
  name: string
  price: number
}

type DikidiServicesGroup = {
  id: string
  name: string
  services: DikidiService[]
}


export const ServicesSection: React.FC = () => {

  const [apiData, setApiData] = useState<DikidiServicesGroup[]>([])

  const getApiData = async () => {
    const url = encodeURIComponent("https://dikidi.ru/mobile/ajax/newrecord/company_services/?lang=ru&array=1&company=1273012&master=2773962&share=")
    await fetch("https://api.allorigins.win/raw?url=" + url,
    )
      .then((response) => response.json())
      .then(res => {
        if (res?.data?.list?.length) {
          setApiData(res.data.list)
        }
      })

  };
  useEffect(() => {
    getApiData()
  }, [])



  return (
    <section className={classNames(cls.ServicesSection, {}, ['container'])}>
      <div className={cls.header}>
        <h1>Вы можете ознакомиться с перечнем предоставляемых услуг</h1>
        <h2>Если у Вас появились вопросы, мы с радостью поможем подобрать процедуры которые подходят именно Вам</h2>
        <div className={cls.contacts}>

          <a className={cls.phone} href={phoneLink}>{phone}</a>
          <a href={`https://wa.me/${phone.replace(/\D/gi, '')}`}><img src={WhatsappSVG} /></a>
          <a href={`https://t.me/+${phone.replace(/\D/gi, '')}`}><img src={TelegramSVG} /></a>
        </div>
      </div>
      <div>

        <div className={cls.content}>

          {
            apiData.length > 0 && apiData.map(group => {
              return (
              <div>
                <h3>{group.name}</h3>
                {group.services.map((item) => <ListItem title={item.name} value={formatMoney(item.price)} />)}
              </div>
              )
            })
          }

          {/* <div>
            <h3>Для женщин</h3>
            {manPrice.map((item) => <ListItem title={item.title} value={formatMoney(item.price)} />)}
          </div>
          <div>
            <h3>Для мужчин</h3>
            {womanPrice.map((item) => <ListItem title={item.title} value={formatMoney(item.price)} />)}
          </div> */}
        </div>
      </div>
    </section>
  );
};