import cls from "./ListItem.module.scss";
import { classNames } from "@/shared/lib/classNames";


interface ListItemProps {
  className?: string;
  title: React.ReactNode
  value: React.ReactNode
}

export const ListItem: React.FC<ListItemProps> = (props) => {
  const {
    className = '',
    title,
    value
  } = props;
  return (
    <div className={classNames(cls.ListItem, {}, [className])}>
      <span>
        <p>{title}</p>
        <p>{value}</p>
      </span>
    </div>
  );
};