import { FC } from "react";
import cls from './InfoCardSmall.module.scss'
import { classNames } from "@/shared/lib/classNames";

interface InfoCardSmallProps {
    text?: string
    url?: string
    className?:string
}

const InfoCardSmall: FC<InfoCardSmallProps> = (props) => {
    const { text, url, className } = props
    return <div style={{ backgroundImage: `url(${url})` }} className={classNames(cls.InfoCardSmall, {}, [className || ''])}>
        <span>
        <p>{text}</p>
        </span>
    </div>
}

export default InfoCardSmall;